import { GetServerSideProps } from "next";
import { ReactElement } from "react";
import LawyerConsultationWrapper from "@components/layouts/lawyerConsultationWrapper";
import MainBanner from "@components/lawyerconsultation/mainBanner";
import dynamic from "next/dynamic";
import { getPageProps } from "@services/initial-calls";

const WhyUs = dynamic(() => import("@components/lawyerconsultation/why_us"), {
    ssr: false,
});

const InjuredInAccident = dynamic(
    () => import("@components/lawyerconsultation/injured_in_accident"),
    {
        ssr: false,
    },
);

const DUI = dynamic(() => import("@components/lawyerconsultation/dui"), {
    ssr: false,
});

const HurtProduct = dynamic(
    () => import("@components/lawyerconsultation/hurt_product"),
    {
        ssr: false,
    },
);

const HurtDrug = dynamic(
    () => import("@components/lawyerconsultation/hurt_drug"),
    {
        ssr: false,
    },
);

const HurtSurgery = dynamic(
    () => import("@components/lawyerconsultation/surgery"),
    {
        ssr: false,
    },
);

const AboutUs = dynamic(
    () => import("@components/lawyerconsultation/about_us"),
    {
        ssr: false,
    },
);

const PartnerBlock = dynamic(
    () => import("@components/lawyerconsultation/partnerBlock"),
    {
        ssr: false,
    },
);

export default function InsureYourCarHome(): ReactElement {
    return (
        <LawyerConsultationWrapper>
            <MainBanner />
            <WhyUs />
            <InjuredInAccident />
            <DUI />
            <HurtProduct />
            <HurtDrug />
            <HurtSurgery />
            <AboutUs />
            <PartnerBlock />
        </LawyerConsultationWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home");
};
