import { ReactElement } from "react";

export default function HurtIcon({
    primaryColor,
    secondaryColor,
}: {
    primaryColor: string;
    secondaryColor: string;
}): ReactElement {
    return (
        <svg
            width="76"
            height="81"
            viewBox="0 0 76 81"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.3214 56.0383H22.2169C21.5077 56.0243 20.8095 55.8601 20.1683 55.5563C19.5271 55.2526 18.9574 54.8162 18.4969 54.2761C18.0705 53.7786 17.7468 53.2015 17.5444 52.5781C17.3421 51.9547 17.2652 51.2973 17.3181 50.644C17.371 49.9906 17.5527 49.3543 17.8528 48.7716C18.1528 48.189 18.5652 47.6717 19.0661 47.2495L19.1165 47.2648H1.40842V78.9847H33.3276V48.2692L25.5954 54.84C24.6793 55.6133 23.5197 56.0377 22.3214 56.0383V56.0383Z"
                fill={primaryColor}
            />
            <path
                d="M0 42.3247V46.3191H19.9906L24.7972 42.3247H0Z"
                fill={primaryColor}
            />
            <path
                d="M29.6221 14.3823C29.6223 12.1472 28.9605 9.96223 27.7203 8.10371C26.4801 6.2452 24.7173 4.7966 22.6547 3.94111C20.5921 3.08562 18.3225 2.86166 16.1327 3.29756C13.943 3.73347 11.9316 4.80964 10.3528 6.39C8.77409 7.97036 7.69891 9.98391 7.26326 12.176C6.82762 14.3682 7.05109 16.6404 7.90541 18.7053C8.75973 20.7703 10.2065 22.5353 12.0628 23.777C13.9192 25.0188 16.1016 25.6816 18.3342 25.6816C21.3276 25.6813 24.1984 24.4908 26.3152 22.3718C28.432 20.2529 29.6215 17.379 29.6221 14.3823V14.3823Z"
                fill={primaryColor}
            />
            <path
                d="M67.7507 74.1563L61.9442 54.3019L64.3593 38.0649C65.575 35.5731 66.2486 32.8346 65.8942 29.6368C64.432 16.4225 30.8676 5.11727 30.8676 8.2552C30.8676 14.3748 29.2411 26.6105 31.736 26.9347L30.8066 38.4009L19.6677 47.8933C18.8414 48.5974 18.3282 49.6012 18.2412 50.6839C18.1542 51.7667 18.5003 52.8397 19.2036 53.667C19.9068 54.4942 20.9095 55.0079 21.991 55.095C23.0726 55.1822 24.1444 54.8356 24.9707 54.1316L37.3089 43.6194C38.0822 42.9625 38.5841 42.0413 38.7171 41.0348L40.1171 30.3511C43.681 31.8783 47.7578 33.993 51.1961 37.0075L43.3571 51.1159C43.0435 51.6806 42.8535 52.3058 42.7997 52.9497L40.9421 75.055C40.8909 75.6524 40.9579 76.254 41.1393 76.8255C41.3206 77.3969 41.6127 77.9269 41.9989 78.3852C42.3851 78.8435 42.8577 79.2211 43.3898 79.4963C43.9219 79.7716 44.503 79.939 45.0998 79.9892C45.2289 79.9998 45.3591 80.0057 45.4871 80.0057C46.6283 80.0041 47.7274 79.5739 48.567 78.8001C49.4066 78.0263 49.9256 76.9653 50.0215 75.8269L51.797 54.6967L52.9951 52.5303L52.7839 53.953C52.6871 54.606 52.7332 55.2722 52.9188 55.9056L59.0058 76.7185C59.3453 77.8795 60.1316 78.8578 61.1917 79.4384C61.7167 79.7258 62.293 79.907 62.8878 79.9715C63.4826 80.0359 64.0843 79.9825 64.6585 79.8142C65.2327 79.6458 65.7682 79.366 66.2343 78.9905C66.7005 78.615 67.0882 78.1513 67.3753 77.6258C67.6625 77.1003 67.8434 76.5233 67.9078 75.9278C67.9722 75.3323 67.9188 74.7299 67.7507 74.1551V74.1563Z"
                fill={primaryColor}
            />
            <path
                d="M62.8288 1.04558L57.5187 13.8052L58.2134 0L62.8288 1.04558Z"
                fill={secondaryColor}
            />
            <path
                d="M73.5493 18.0544L59.7595 17.3589L72.5049 22.6749L73.5493 18.0544Z"
                fill={secondaryColor}
            />
            <path
                d="M73.1895 3.59131L58.6392 15.5826L75.7148 7.59859L73.1895 3.59131Z"
                fill={secondaryColor}
            />
        </svg>
    );
}
