import AccidentIcon from "./icons/accident";
import DrugIcon from "./icons/drug";
import HurtIcon from "./icons/hurt";
import DUIIcon from "./icons/dui";
import ProductHarmIcon from "./icons/product_harm";
import BankruptcyIcon from "./icons/bankruptcy";
import SocialSecurityDisabilityIcon from "./icons/social_security_disability";
import colors from "@styles/variables/lawyerconsultationVariables.module.scss";

export const categoryList = [
    {
        title: "SERVICE_AUTOMOTIVE_ACCIDENT",
        icon: (
            <AccidentIcon
                primaryColor={colors.lcPrimary}
                secondaryColor={colors.lcLightPrimary}
            />
        ),
        href: "auto-accident",
        class: "accidentIcon",
    },
    {
        title: "SERVICE_INJURED_AT_WORK",
        icon: (
            <HurtIcon
                primaryColor={colors.lcPrimary}
                secondaryColor={colors.lcLightPrimary}
            />
        ),
        href: "worker-compensation",
        class: "hurtIcon",
    },
    {
        title: "SERVICE_DUI",
        icon: (
            <DUIIcon
                primaryColor={colors.lcPrimary}
                secondaryColor={colors.lcLightPrimary}
            />
        ),
        href: "dui",
        class: "accidentIcon",
    },
    {
        title: "SERVICE_HARMFUL_PRODUCTS",
        icon: (
            <ProductHarmIcon
                primaryColor={colors.lcPrimary}
                secondaryColor={colors.lcLightPrimary}
            />
        ),
        href: "harmful-products",
        class: "productHarmIcon",
    },
    {
        title: "SERVICE_HARMFUL_DRUGS",
        icon: (
            <DrugIcon
                primaryColor={colors.lcPrimary}
                secondaryColor={colors.lcLightPrimary}
            />
        ),
        href: "harmful-drugs",
        class: "drugIcon",
    },
    {
        title: "SERVICE_SOCIAL_SECURITY_DISABILITY",
        icon: (
            <SocialSecurityDisabilityIcon
                primaryColor={colors.lcPrimary}
                secondaryColor={colors.lcLightPrimary}
            />
        ),
        href: "social-security-disability",
        class: "socialSecurityDisabilityIcon",
    },
    {
        title: "SERVICE_BANKRUPTCY",
        icon: (
            <BankruptcyIcon
                primaryColor={colors.lcPrimary}
                secondaryColor={colors.lcLightPrimary}
            />
        ),
        href: "bankruptcy",
        class: "accidentIcon",
    },
];
