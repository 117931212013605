import { ReactElement } from "react";
import style from "./style.module.scss";
import { useTranslation } from "@hooks/useTranslation";
import { categoryList } from "./categoryList";
import { useDomainContext } from "@hooks/useDomainContext";

export default function MainBanner(): ReactElement {
    const { translate } = useTranslation();
    const { locale, defaultLocale } = useDomainContext();

    return (
        <div
            className={`${style.mainBanner} flex  items-center justify-center`}
        >
            <div className={`${style.w1220}`}>
                <div>
                    <h1
                        className={`${style.title} text-xl  md:text-4xl max-w-2xl mx-auto  text-center mb-2 sm:mb-6 md:mb-10`}
                    >
                        {translate("MAIN_BANNER_TITLE")}
                    </h1>
                    <p className={`${style.brief} text-center mb-8 sm:mb-12`}>
                        {translate("MAIN_BANNER_DESCRIPTION")}
                    </p>
                </div>

                <div
                    className={`${style.serviceBlocks} flex flex-wrap justify-center text-center text-lg mx-auto`}
                >
                    {categoryList.map((item, idx) => (
                        <a
                            href={
                                locale === defaultLocale
                                    ? `/${item.href}`
                                    : `/${locale}/${item.href}`
                            }
                            className={`${style.serviceBlockLnk} ${
                                style[item.class]
                            }`}
                            key={idx}
                        >
                            <span
                                className={`${style.serviceBlock} flex items-center justify-center rounded-lg mb-4`}
                            >
                                {item.icon}
                            </span>
                            <span>{translate(item.title)}</span>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
}
