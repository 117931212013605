import { ReactElement } from "react";

export default function DrugIcon({
    primaryColor,
    secondaryColor,
}: {
    primaryColor: string;
    secondaryColor: string;
}): ReactElement {
    return (
        <svg
            width="53"
            height="91"
            viewBox="0 0 53 91"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M36.0587 11.5889H14.6167V19.5817H36.0587V11.5889Z"
                fill="white"
            />
            <path
                d="M1.0127 24.277C1.0127 22.2639 1.81175 20.3332 3.23409 18.9097C4.65642 17.4862 6.58551 16.6865 8.59699 16.6865H40.3998C41.3959 16.6864 42.3823 16.8826 43.3027 17.2639C44.223 17.6453 45.0593 18.2044 45.7638 18.9092C46.4682 19.6141 47.0271 20.4509 47.4084 21.3719C47.7896 22.2929 47.9859 23.28 47.986 24.277V76.5437C47.9861 77.5406 47.79 78.5277 47.4088 79.4487C47.0277 80.3697 46.4689 81.2065 45.7646 81.9114C45.0602 82.6163 44.224 83.1753 43.3038 83.5567C42.3835 83.9381 41.3972 84.1343 40.4011 84.1342H8.59699C7.60096 84.1343 6.61466 83.9381 5.69441 83.5567C4.77417 83.1753 3.93801 82.6162 3.23371 81.9114C2.52941 81.2065 1.97076 80.3696 1.58968 79.4487C1.20859 78.5277 1.01253 77.5406 1.0127 76.5437V24.277V24.277Z"
                fill="white"
            />
            <path
                d="M40.4006 85.148H8.59774C3.85715 85.148 0 81.2884 0 76.5433V24.2766C0 19.5322 3.85715 15.6719 8.59774 15.6719H40.4006C45.1418 15.6719 48.9983 19.5322 48.9983 24.2766V76.5433C49.0015 81.2884 45.1418 85.148 40.4006 85.148ZM8.59774 17.6985C6.85532 17.7005 5.18488 18.3943 3.95299 19.6275C2.72109 20.8608 2.02839 22.5328 2.02687 24.2766V76.5433C2.02872 78.2869 2.72158 79.9586 3.95344 81.1916C5.1853 82.4246 6.85554 83.1181 8.59774 83.1202H40.4006C42.1429 83.1183 43.8133 82.4248 45.0453 81.1918C46.2773 79.9588 46.9702 78.287 46.9721 76.5433V24.2766C46.9705 22.5326 46.2777 20.8606 45.0457 19.6273C43.8137 18.394 42.1431 17.7003 40.4006 17.6985H8.59774Z"
                fill={primaryColor}
            />
            <path
                d="M39.0099 19.5884C40.4542 19.5885 41.8394 20.1629 42.8607 21.1851C43.8819 22.2073 44.4557 23.5937 44.4557 25.0392V28.9509L39.0099 19.5884Z"
                fill="white"
            />
            <path
                d="M44.4549 29.9649C44.1857 29.9649 43.9276 29.8579 43.7373 29.6675C43.5471 29.477 43.4401 29.2187 43.4401 28.9494V25.0389C43.4385 23.8629 42.971 22.7355 42.1401 21.9039C41.3093 21.0722 40.1829 20.6042 39.0078 20.6023C38.7387 20.6023 38.4806 20.4954 38.2903 20.3049C38.1 20.1145 37.9931 19.8562 37.9931 19.5868C37.9931 19.3175 38.1 19.0592 38.2903 18.8687C38.4806 18.6783 38.7387 18.5713 39.0078 18.5713C40.7201 18.574 42.3615 19.256 43.5722 20.4679C44.7829 21.6798 45.464 23.3227 45.4664 25.0364V28.9481C45.4667 29.2171 45.3604 29.4753 45.1708 29.6659C44.9811 29.8565 44.7236 29.9641 44.4549 29.9649Z"
                fill={primaryColor}
            />
            <path
                d="M35.0486 17.0193C34.7795 17.0193 34.5214 16.9123 34.3311 16.7218C34.1408 16.5314 34.0339 16.2731 34.0339 16.0037V12.5985C34.0339 12.3292 34.1408 12.0709 34.3311 11.8804C34.5214 11.69 34.7795 11.583 35.0486 11.583C35.3177 11.583 35.5758 11.69 35.7661 11.8804C35.9564 12.0709 36.0633 12.3292 36.0633 12.5985V16.005C36.0629 16.2741 35.9559 16.5321 35.7656 16.7223C35.5754 16.9124 35.3175 17.0193 35.0486 17.0193Z"
                fill={primaryColor}
            />
            <path
                d="M14.6286 17.0193C14.3595 17.0193 14.1014 16.9123 13.9111 16.7218C13.7208 16.5314 13.6139 16.2731 13.6139 16.0037V12.5985C13.6139 12.3292 13.7208 12.0709 13.9111 11.8804C14.1014 11.69 14.3595 11.583 14.6286 11.583C14.8977 11.583 15.1558 11.69 15.3461 11.8804C15.5364 12.0709 15.6433 12.3292 15.6433 12.5985V16.005C15.643 16.2741 15.5359 16.5321 15.3457 16.7223C15.1554 16.9124 14.8975 17.0193 14.6286 17.0193Z"
                fill={primaryColor}
            />
            <path
                d="M41.1785 12.5984H7.82001V3.01431C7.82018 2.48428 8.03063 1.976 8.40512 1.60121C8.77961 1.22642 9.28747 1.01579 9.81708 1.01562H39.1802C39.7098 1.01579 40.2176 1.22642 40.5921 1.60121C40.9666 1.976 41.1771 2.48428 41.1772 3.01431L41.1785 12.5984Z"
                fill={secondaryColor}
            />
            <path
                d="M41.1784 13.6125H7.81991C7.5508 13.6125 7.2927 13.5055 7.10241 13.3151C6.91211 13.1246 6.80521 12.8663 6.80521 12.597V3.01295C6.80621 2.21417 7.12371 1.4484 7.68807 0.883583C8.25244 0.318764 9.01759 0.00100771 9.81571 0H39.1788C39.977 0.000840074 40.7422 0.318542 41.3066 0.883397C41.871 1.44825 42.1885 2.21412 42.1893 3.01295V12.597C42.1893 12.8657 42.0829 13.1234 41.8935 13.3137C41.704 13.5041 41.4469 13.6115 41.1784 13.6125ZM8.83462 11.5815H40.1637V3.01295C40.1634 2.75185 40.0595 2.50156 39.875 2.317C39.6905 2.13244 39.4403 2.02869 39.1794 2.02852H9.81635C9.55552 2.02869 9.30543 2.13246 9.121 2.31704C8.93657 2.50162 8.83288 2.75191 8.83272 3.01295L8.83462 11.5815Z"
                fill={primaryColor}
            />
            <path
                d="M11.9057 13.6124C11.6366 13.6124 11.3785 13.5055 11.1882 13.315C10.9979 13.1246 10.891 12.8662 10.891 12.5969V7.82901C10.891 7.55967 10.9979 7.30137 11.1882 7.11092C11.3785 6.92047 11.6366 6.81348 11.9057 6.81348C12.1748 6.81348 12.4329 6.92047 12.6232 7.11092C12.8135 7.30137 12.9204 7.55967 12.9204 7.82901V12.5982C12.92 12.8673 12.813 13.1253 12.6227 13.3155C12.4325 13.5056 12.1746 13.6124 11.9057 13.6124Z"
                fill={primaryColor}
            />
            <path
                d="M18.0319 13.6124C17.7628 13.6124 17.5047 13.5055 17.3144 13.315C17.1241 13.1246 17.0172 12.8662 17.0172 12.5969V7.82901C17.0172 7.55967 17.1241 7.30137 17.3144 7.11092C17.5047 6.92047 17.7628 6.81348 18.0319 6.81348C18.3011 6.81348 18.5592 6.92047 18.7495 7.11092C18.9398 7.30137 19.0467 7.55967 19.0467 7.82901V12.5982C19.0463 12.8673 18.9393 13.1253 18.749 13.3155C18.5587 13.5056 18.3008 13.6124 18.0319 13.6124Z"
                fill={primaryColor}
            />
            <path
                d="M24.8387 13.6124C24.5696 13.6124 24.3115 13.5055 24.1212 13.315C23.9309 13.1246 23.824 12.8662 23.824 12.5969V7.82901C23.824 7.55967 23.9309 7.30137 24.1212 7.11092C24.3115 6.92047 24.5696 6.81348 24.8387 6.81348C25.1079 6.81348 25.366 6.92047 25.5562 7.11092C25.7465 7.30137 25.8534 7.55967 25.8534 7.82901V12.5982C25.8531 12.8673 25.7461 13.1253 25.5558 13.3155C25.3655 13.5056 25.1076 13.6124 24.8387 13.6124Z"
                fill={primaryColor}
            />
            <path
                d="M30.9671 13.6124C30.6979 13.6124 30.4399 13.5055 30.2496 13.315C30.0593 13.1246 29.9524 12.8662 29.9524 12.5969V7.82901C29.9524 7.55967 30.0593 7.30137 30.2496 7.11092C30.4399 6.92047 30.6979 6.81348 30.9671 6.81348C31.2362 6.81348 31.4943 6.92047 31.6846 7.11092C31.8749 7.30137 31.9818 7.55967 31.9818 7.82901V12.5982C31.9814 12.8673 31.8744 13.1253 31.6841 13.3155C31.4939 13.5056 31.236 13.6124 30.9671 13.6124Z"
                fill={primaryColor}
            />
            <path
                d="M37.0923 13.6124C36.8232 13.6124 36.5651 13.5055 36.3748 13.315C36.1845 13.1246 36.0776 12.8662 36.0776 12.5969V7.82901C36.0776 7.55967 36.1845 7.30137 36.3748 7.11092C36.5651 6.92047 36.8232 6.81348 37.0923 6.81348C37.3615 6.81348 37.6196 6.92047 37.8098 7.11092C38.0001 7.30137 38.107 7.55967 38.107 7.82901V12.5982C38.1067 12.8673 37.9997 13.1253 37.8094 13.3155C37.6191 13.5056 37.3612 13.6124 37.0923 13.6124Z"
                fill={primaryColor}
            />
            <path
                d="M24.9199 87.9319C24.8051 88.0087 24.6897 88.0805 24.5718 88.1484C23.2231 88.9202 21.6302 89.1456 20.1206 88.7782C18.611 88.4108 17.2995 87.4786 16.4556 86.1731C15.6116 84.8676 15.2996 83.2882 15.5834 81.7596C15.8673 80.2309 16.7256 78.8692 17.9819 77.9544L26.8219 71.5273C27.7175 70.8777 28.7733 70.4851 29.8754 70.3918C30.9776 70.2985 32.0843 70.508 33.0762 70.9978C34.0681 71.4877 34.9077 72.2392 35.5042 73.1714C36.1008 74.1036 36.4318 75.181 36.4616 76.2876L24.9199 87.9319Z"
                fill="white"
            />
            <path
                d="M21.5506 89.9729C20.0536 89.9715 18.5955 89.4961 17.385 88.6148C16.1744 87.7334 15.2736 86.4913 14.8114 85.0664C14.3492 83.6414 14.3494 82.1066 14.812 80.6817C15.2745 79.2569 16.1757 78.015 17.3865 77.134L26.2265 70.707C27.2713 69.9478 28.5035 69.4886 29.79 69.3791C31.0766 69.2695 32.3686 69.5137 33.5266 70.0852C34.6846 70.6567 35.6646 71.534 36.361 72.6222C37.0573 73.7104 37.4434 74.9683 37.4777 76.26C37.4846 76.5294 37.3843 76.7904 37.1989 76.9857C37.0134 77.1811 36.7581 77.2947 36.489 77.3016C36.2198 77.3085 35.959 77.2081 35.7638 77.0225C35.5687 76.837 35.4551 76.5814 35.4483 76.3121C35.4233 75.3902 35.1474 74.4927 34.6503 73.7163C34.1533 72.9398 33.4539 72.3138 32.6276 71.9059C31.8012 71.4979 30.8793 71.3234 29.9612 71.4011C29.0431 71.4788 28.1636 71.8059 27.4175 72.347L18.5775 78.7741C17.5284 79.5352 16.811 80.67 16.573 81.9447C16.335 83.2195 16.5947 84.537 17.2984 85.6258C18.0021 86.7147 19.0964 87.4919 20.3556 87.7974C21.6149 88.1028 22.9433 87.9133 24.067 87.2677C24.1641 87.2119 24.2573 87.1529 24.3543 87.0888C24.5774 86.9534 24.8441 86.9093 25.0988 86.9656C25.3536 87.0218 25.5769 87.1741 25.7224 87.3908C25.8679 87.6075 25.9245 87.872 25.8803 88.1293C25.8361 88.3866 25.6946 88.617 25.4851 88.7726C25.3513 88.8628 25.2156 88.9465 25.0773 89.0265C24.005 89.6461 22.7888 89.9725 21.5506 89.9729V89.9729Z"
                fill={primaryColor}
            />
            <path
                d="M22.5433 75.1055L24.5341 77.5745L22.5433 75.1055Z"
                fill="white"
            />
            <path
                d="M24.5332 78.5889C24.3818 78.5892 24.2322 78.5554 24.0955 78.4901C23.9588 78.4247 23.8386 78.3294 23.7437 78.2113L21.7536 75.7429C21.6008 75.5322 21.5347 75.2708 21.5689 75.0127C21.6031 74.7547 21.7351 74.5196 21.9375 74.3561C22.14 74.1926 22.3974 74.1132 22.6567 74.1342C22.916 74.1552 23.1573 74.2751 23.3308 74.469L25.3209 76.9381C25.4401 77.0871 25.515 77.2668 25.5368 77.4565C25.5587 77.6462 25.5266 77.8382 25.4444 78.0105C25.3622 78.1828 25.2331 78.3284 25.0719 78.4306C24.9107 78.5328 24.724 78.5875 24.5332 78.5883V78.5889Z"
                fill={primaryColor}
            />
            <path
                d="M46.0097 82.7312C46.0111 83.5526 45.8506 84.3662 45.5374 85.1254C45.2243 85.8847 44.7646 86.5746 44.1846 87.1558C43.6047 87.737 42.9159 88.198 42.1577 88.5124C41.3995 88.8268 40.5868 88.9885 39.7661 88.9882H34.3754V76.4756H39.7661C40.5865 76.4757 41.399 76.6376 42.1569 76.9521C42.9148 77.2666 43.6034 77.7275 44.1832 78.3084C44.7631 78.8894 45.2228 79.579 45.5362 80.3379C45.8496 81.0968 46.0105 81.9101 46.0097 82.7312V82.7312Z"
                fill={secondaryColor}
            />
            <path
                d="M39.766 90.0021H34.3753C34.1062 90.0021 33.8481 89.8951 33.6578 89.7046C33.4675 89.5142 33.3606 89.2559 33.3606 88.9865V76.4765C33.3606 76.2071 33.4675 75.9488 33.6578 75.7584C33.8481 75.5679 34.1062 75.4609 34.3753 75.4609H39.766C43.7677 75.4609 47.023 78.7221 47.023 82.7302C47.0244 83.6848 46.8378 84.6302 46.4736 85.5125C46.1095 86.3947 45.5752 87.1964 44.9011 87.8718C44.227 88.5471 43.4265 89.0827 42.5454 89.448C41.6642 89.8134 40.7197 90.0012 39.766 90.0008V90.0021ZM35.3862 87.971H39.766C41.1551 87.971 42.4874 87.4187 43.4696 86.4356C44.4519 85.4526 45.0037 84.1192 45.0037 82.7289C45.0037 81.3387 44.4519 80.0053 43.4696 79.0223C42.4874 78.0392 41.1551 77.4869 39.766 77.4869H35.3862V87.971Z"
                fill={primaryColor}
            />
            <path
                d="M34.3728 76.4756V88.9882H28.9866C27.3287 88.9882 25.7386 88.329 24.5663 87.1557C23.394 85.9825 22.7354 84.3912 22.7354 82.7319C22.7354 81.0726 23.394 79.4813 24.5663 78.308C25.7386 77.1347 27.3287 76.4756 28.9866 76.4756H34.3728Z"
                fill="white"
            />
            <path
                d="M34.3731 90.0018H28.9869C27.55 90.0023 26.1453 89.5763 24.9504 88.7777C23.7556 87.979 22.8243 86.8436 22.2743 85.5151C21.7244 84.1866 21.5806 82.7247 21.861 81.3143C22.1415 79.904 22.8337 78.6086 23.8499 77.592C24.5221 76.9136 25.3225 76.3759 26.2044 76.0101C27.0864 75.6444 28.0322 75.4581 28.9869 75.462H34.3731C34.6422 75.462 34.9003 75.569 35.0906 75.7594C35.2809 75.9499 35.3878 76.2082 35.3878 76.4775V88.9894C35.3869 89.2582 35.2797 89.5157 35.0895 89.7055C34.8993 89.8952 34.6416 90.0018 34.3731 90.0018ZM28.9869 77.488C27.5979 77.4885 26.2659 78.0409 25.2838 79.0239C24.3016 80.0068 23.7496 81.3399 23.7491 82.73C23.7506 84.1198 24.3029 85.4522 25.2849 86.435C26.2668 87.4177 27.5982 87.9705 28.9869 87.972H33.3596V77.488H28.9869Z"
                fill={primaryColor}
            />
            <path
                d="M51.9618 66.9028C51.9614 68.0647 51.6367 69.2034 51.0245 70.1906L48.177 74.7738L37.565 68.1729L40.4062 63.5897C41.1229 62.4333 42.197 61.5425 43.4655 61.0524C44.7339 60.5623 46.1274 60.4997 47.4346 60.8741C48.7418 61.2485 49.8913 62.0394 50.7087 63.1268C51.5261 64.2143 51.9667 65.5389 51.9637 66.8997L51.9618 66.9028Z"
                fill="white"
            />
            <path
                d="M48.1764 75.7881C47.9876 75.788 47.8026 75.735 47.6424 75.6351L37.0311 69.0342C36.8031 68.8921 36.6407 68.6653 36.5794 68.4035C36.5182 68.1418 36.5632 67.8664 36.7045 67.6378L39.5457 63.0552C41.6544 59.6551 46.1413 58.604 49.5463 60.7125C51.1817 61.7297 52.347 63.3549 52.7862 65.2312C53.2254 67.1075 52.9027 69.0815 51.889 70.7199L49.0351 75.3063C48.9449 75.4532 48.8186 75.5746 48.6683 75.6589C48.518 75.7432 48.3487 75.7876 48.1764 75.7881ZM38.961 67.8492L47.8517 73.3774L50.1665 69.6549C50.8988 68.4734 51.1322 67.0491 50.8152 65.6953C50.4983 64.3416 49.657 63.1693 48.4764 62.4364C47.2958 61.7035 45.8726 61.4699 44.52 61.7871C43.1674 62.1044 41.9961 62.9463 41.2637 64.1279L38.961 67.8492Z"
                fill={primaryColor}
            />
            <path
                d="M45.332 80.3713C45.1517 80.3707 44.9747 80.3221 44.8194 80.2304C44.664 80.1386 44.5359 80.0071 44.4483 79.8493C44.3606 79.6916 44.3165 79.5133 44.3206 79.3328C44.3247 79.1524 44.3767 78.9762 44.4714 78.8226L47.3139 74.2382C47.4625 74.025 47.6871 73.877 47.9414 73.8246C48.1958 73.7722 48.4605 73.8193 48.6812 73.9564C48.9018 74.0934 49.0615 74.3099 49.1275 74.5613C49.1935 74.8127 49.1606 75.0798 49.0357 75.3076L46.1939 79.8914C46.1029 80.0382 45.976 80.1592 45.8251 80.2432C45.6743 80.3272 45.5046 80.3713 45.332 80.3713Z"
                fill={primaryColor}
            />
            <path
                d="M34.9745 73.3512C34.794 73.3506 34.6168 73.3019 34.4614 73.2099C34.306 73.118 34.1778 72.9862 34.0903 72.8282C34.0027 72.6702 33.9588 72.4916 33.9632 72.311C33.9676 72.1304 34.0201 71.9542 34.1152 71.8006L36.7027 67.6394C36.7694 67.5189 36.8598 67.4132 36.9686 67.3288C37.0773 67.2444 37.2021 67.183 37.3353 67.1484C37.4685 67.1137 37.6074 67.1066 37.7434 67.1273C37.8795 67.1481 38.0099 67.1963 38.1268 67.2691C38.2436 67.3419 38.3444 67.4378 38.4231 67.5508C38.5018 67.6638 38.5566 67.7917 38.5844 67.9266C38.6121 68.0615 38.6121 68.2007 38.5844 68.3356C38.5567 68.4705 38.5019 68.5984 38.4233 68.7115L35.8358 72.8726C35.7446 73.019 35.6177 73.1398 35.4671 73.2235C35.3164 73.3072 35.1469 73.3512 34.9745 73.3512Z"
                fill={primaryColor}
            />
            <path
                d="M24.4742 27.3711C14.8561 27.3711 7.03394 35.2021 7.03394 44.8287C7.03394 54.4553 14.858 62.2831 24.4742 62.2831C34.0905 62.2831 41.9145 54.4515 41.9145 44.8287C41.9145 35.2059 34.0898 27.3711 24.4742 27.3711V27.3711ZM16.6388 34.0653C18.9116 32.3986 21.6565 31.501 24.4742 31.503C31.8156 31.503 37.7878 37.4801 37.7878 44.8319C37.7898 47.6508 36.8936 50.3969 35.2295 52.6711L16.6388 34.0653ZM13.7215 36.985L32.3097 55.5908C30.0364 57.2566 27.2917 58.1535 24.4742 58.1512C17.1328 58.1512 11.16 52.1742 11.16 44.8281C11.1577 42.0078 12.0544 39.2602 13.7196 36.985H13.7215Z"
                fill="white"
            />
            <path
                d="M24.4741 63.2983C14.2979 63.2983 6.01913 55.0129 6.01913 44.8284C6.01913 34.6439 14.2979 26.3584 24.4741 26.3584C34.6504 26.3584 42.9291 34.6451 42.9291 44.8284C42.9291 55.0116 34.6504 63.2983 24.4741 63.2983ZM24.4741 28.385C15.4153 28.385 8.04855 35.7616 8.04855 44.8284C8.04855 53.8951 15.4185 61.2673 24.4741 61.2673C33.5297 61.2673 40.8997 53.8913 40.8997 44.8284C40.8997 35.7654 33.5335 28.385 24.4741 28.385ZM24.4741 59.1683C16.574 59.1683 10.1465 52.7368 10.1465 44.8309C10.1406 41.7948 11.106 38.8366 12.9014 36.3893C12.9878 36.2713 13.0987 36.1734 13.2264 36.1023C13.3542 36.0312 13.4958 35.9885 13.6415 35.9773C13.7873 35.966 13.9337 35.9864 14.0709 36.037C14.208 36.0876 14.3326 36.1673 14.4361 36.2706L33.0262 54.8758C33.1294 54.9794 33.2089 55.1041 33.2595 55.2414C33.31 55.3787 33.3303 55.5253 33.319 55.6712C33.3077 55.817 33.265 55.9587 33.1939 56.0865C33.1228 56.2144 33.0249 56.3253 32.907 56.4117C30.4618 58.207 27.5067 59.1721 24.4741 59.1657V59.1683ZM13.8831 38.5835C12.7614 40.4737 12.1715 42.6324 12.1759 44.8309C12.1759 51.6178 17.6933 57.1398 24.4792 57.1398C26.6753 57.1443 28.8318 56.5544 30.7203 55.4324L13.8831 38.5835ZM35.2313 53.6895C35.0983 53.6896 34.9666 53.6633 34.8437 53.6124C34.7208 53.5614 34.6092 53.4867 34.5153 53.3925L15.922 34.7822C15.8187 34.6786 15.739 34.5539 15.6883 34.4166C15.6377 34.2792 15.6173 34.1326 15.6286 33.9866C15.6398 33.8407 15.6825 33.6989 15.7536 33.571C15.8247 33.4431 15.9226 33.3321 16.0406 33.2456C18.4863 31.4494 21.4419 30.4835 24.4754 30.4891C32.3755 30.4891 38.8024 36.9212 38.8024 44.8284C38.8086 47.8643 37.8437 50.8225 36.0488 53.27C35.9624 53.3881 35.8516 53.4863 35.7239 53.5576C35.5961 53.6289 35.4545 53.6717 35.3086 53.6831C35.2814 53.6863 35.2547 53.6863 35.2287 53.6863L35.2313 53.6895ZM18.2349 34.2319L35.0708 51.0802C36.1918 49.1902 36.7815 47.032 36.7774 44.8341C36.7774 38.0427 31.26 32.5208 24.4741 32.5208C22.2779 32.5155 20.1212 33.1048 18.2324 34.2262L18.2349 34.2319Z"
                fill={primaryColor}
            />
        </svg>
    );
}
